var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-width":"320","min-height":"320"}},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',{attrs:{"loading":_vm.placesSuggestionsLoading,"hide-details":"","filled":"","placeholder":"search place","readonly":!_vm.isEditable},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchPlaces.apply(null, arguments)}},model:{value:(_vm.placesSearchPhrase),callback:function ($$v) {_vm.placesSearchPhrase=$$v},expression:"placesSearchPhrase"}},[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-crosshairs-gps")]),_c('v-icon',{attrs:{"slot":"append"},on:{"click":_vm.searchPlaces},slot:"append"},[_vm._v("mdi-magnify")]),_c('v-icon',{attrs:{"slot":"append-outer","left":""},on:{"click":function($event){return _vm.$emit('close')}},slot:"append-outer"},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.showPlacesSuggestions),callback:function ($$v) {_vm.showPlacesSuggestions=$$v},expression:"showPlacesSuggestions"}},[_c('v-list',_vm._l((_vm.placesSuggestions),function(place,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.setSelectedPlace(place)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(place.formatted_address)+" ")])],1)],1)}),1)],1),_c('gmap-map',{ref:"gmap",staticStyle:{"min-height":"400px","width":"100%"},attrs:{"center":_vm.mapLocation,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    },"zoom":15,"map-type-id":"terrain"},on:{"click":_vm.setMarkerLocation}},[_c('gmap-marker',{attrs:{"position":_vm.mapMarker}})],1),(_vm.isEditable)?_c('v-btn',{attrs:{"loading":_vm.placesSuggestionsLoading,"block":"","x-large":"","color":"primary"},on:{"click":_vm.confirmLocation}},[_vm._v("Continue ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }