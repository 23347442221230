<template>
  <v-container>
    <v-container v-if="loading">
      <div id="loading">
        <v-progress-circular id="loader" indeterminate color="primary"></v-progress-circular>
      </div>
  
    </v-container>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat rounded color="primary" dark>
            <v-toolbar-title>Users</v-toolbar-title>
            <v-spacer />
            <v-text-field
                v-model="search"
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                hide-details
                filled
                rounded
                dense
            />
            <v-spacer />
            <v-btn
                color="primary"
                rounded
                large
                depressed
                :icon="isMobile"
                @click="loadUsers"
            >
              <v-icon left color="white">mdi-refresh</v-icon>
              <span v-if="!isMobile"> Refresh </span>
            </v-btn>
            <v-btn
                color="primary"
                rounded
                large
                depressed
                :icon="isMobile"
                @click="dialog = true"
            >
              <v-icon left color="white">mdi-plus</v-icon>
              <span v-if="!isMobile"> Create User </span>
            </v-btn>
            <v-btn
                color="primary"
                rounded
                large
                depressed
                :icon="isMobile"
                @click="dialogFactory = true"
            >
              <v-icon left color="white">mdi-plus</v-icon>
              <span v-if="!isMobile"> Create Factory </span>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.update="{ item }">
          <select @change="changeRole(item.id, $event)">
            <!-- <option :selected="item.role.superAdmin" value="superAdmin">Super Admin</option> -->
            <option :selected="item.role.admin" value="admin">Admin</option>
            <option :selected="item.role.fieldRegistrar" value="fieldRegistrar">Field Registrar</option>
            <option :selected="item.role.guest" value="guest">Guest</option>
          </select>
        </template>
        <template v-slot:item.factories="{ item }">
          <v-select2
                        v-model="item.factories"
                        :options="factories"
                        :reduce="(factory) => factory._id"
                        label="name"
                        placeholder="Select Factory"
                        @input="updateFactories(item)"
                      ></v-select2>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-btn
          v-if="user.claims.admin"
            @click.stop="deleteUser(item)"
            color="error"
            depressed
            rounded
            block
          >
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New User</span>
        </v-card-title>
        <form @submit.prevent="processAuth">
          <v-card-text class="text-center">
            <v-text-field v-model="email" filled rounded label="Email" type="email" required/>
            <v-text-field  v-model="password" filled rounded label="Password"
                           type="password" required/>
<!--            <v-btn :loading="loading" block color="primary" large rounded type="submit">-->
<!--              <span v-if="!is_register">Login</span>-->
<!--              <span v-else>Register</span>-->
<!--            </v-btn>-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :disabled="loading"
                :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </form>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFactory" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add New Factory</span>
        </v-card-title>
        <form @submit.prevent="processAddFactory">
          <v-card-text class="text-center">
            <v-text-field v-model="factoryName" filled rounded label="Factory Name" type="text" required/>
            <v-text-field v-model="factoryLocation" filled rounded label="Factory Location" type="text" required/>

            
<!--            <v-btn :loading="loading" block color="primary" large rounded type="submit">-->
<!--              <span v-if="!is_register">Login</span>-->
<!--              <span v-else>Register</span>-->
<!--            </v-btn>-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogFactory = false">
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :disabled="loading"
                :loading="loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </form>

      </v-card>
    </v-dialog>
  </v-container>

</template>
<script>
import firebase from "firebase";

export default {
  name:'Users',
  data () {
    return {
      factoryName: '',
      search: '',
      users: [],
      user: null,
      email:'',
      password:'',
      factories: [],
      roles:['superAdmin','admin','fieldRegistrar','guest'],
      loading:false,
      dialog:false,
      dialogFactory: false,
      factoryLocation: "",
      headers: [

        { text: 'Email', value: 'email' },
        { text: 'Factory', value: 'factories' },
        { text: '', value: 'update' },
        { text: '', value: 'delete' },
      ],

    }
  },
  created() {
    this.loadUsers();
    // console.log('####',this.users);
  },
  async mounted(){
    this.token = await firebase.auth().currentUser.getIdToken(true);

    this.fetchFactories();
  },
  methods: {
    async loadUsers() {
      this.loading = true;
    firebase.auth().currentUser.getIdTokenResult().then((user) => {
      this.user = user;

      // console.log(this.user.claims);

    });

    this.users = [];
    firebase
        .firestore()
        .collection("roles")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            const user = doc.data();
            user.id = doc.id;
            // console.log(doc.data());

            user.factories = (user.factories || []).filter((f) => f);
            // if (!user.role.admin)
              this.users.push(user);
          });
    this.loading = false;

        });
    },
    async fetchFactories(){
      this.$axios
      .get("/factories")
      .then((res) => {
        this.factories = (res.data || []).map((factory) => ({
          ...factory
        }));
      })
      .catch((err) => {
        console.error(err);
      });
    },
    async processAuth() {
      this.loading = true
      await this.registerUser()
      this.loading = false
    },
    processAddFactory(){
      var self = this;

      if (this.factoryName==='' ||this.factoryLocation==='')return
      try {
        const data={name: this.factoryName, location:this.factoryLocation}

        this.$axios
        .post("/factories", data)
        .then((res) => {
          console.log("saved factory", res.data);

          self.factoryName = "";
              self.factoryLocation = "";

              this.fetchFactories();

              self.dialogFactory = false;


          // this.$router.push({ name: "farmers" });
        })
        .catch((err) => {
          
        })
        .finally(() => {
          this.loading = false;
        });

      } catch (error) {
        console.log(error.message);
      }

    },

    updateFactories(item) {

      // console.log(item.factories);

      const _updateFactories = firebase.functions().httpsCallable("updateFactories");

      const data = {uid: item.id, factories: [item.factories] || []};

      this.loading = true;

      _updateFactories(data)
          .then(function(result) {
            console.log(result);
          })
          .catch(function(error) {
            console.log(error)
          }).finally(() => {

      this.loading = false;


          });


    },

    deleteUser(item) {

// console.log(item.factories);

const _deleteUser = firebase.functions().httpsCallable("deleteUser");

const data = {uid: item.id,};

this.loading = true;

_deleteUser(data)
    .then((result) => {
      console.log(result);
      this.loadUsers()
    })
    .catch(function(error) {
      console.log(error)
    }).finally(() => {

this.loading = false;


    });


},

    changeRole(uid, event) {
      const setUserRole = firebase.functions().httpsCallable("setUserRole");

      const data = {uid: uid, role: {[event.target.value]: true}};

      this.loading = true;

      setUserRole(data)
          .then(function(result) {
            console.log(result);
          })
          .catch(function(error) {
            console.log(error)
          }).finally(() => this.loading = false);
    },
    async registerUser() {
      if (this.email==='' ||this.password==='')return
      try {
        const addUser =firebase.functions().httpsCallable("addUser");
        const data={email: this.email, password:this.password}
        addUser(data)
            .then((result) => {
              console.log(result);

              this.email =  "";
              this.password = "";
              this.dialog = false;

      this.loadUsers()


            })
            .catch(function(error) {
              console.log(error)
            });

      } catch (error) {
        console.log(error.message);
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

  },
}

</script>
